/* ./components/home/login.css */

/* ./components/home/login.css */

/* Universal Styles */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    background: linear-gradient(110deg, #2a3f5e, #3d5a80, #e2e7ec); /* Softened transition without fully shifting to #F0EDEE */
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
}



.welcome-section {
    text-align: center;
    margin-bottom: 50px;
}

.welcome-section .logo {
    font-size: 3.5em;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
}

.welcome-section p {
    font-size: 1.2em;
    color: #d1d1d1;
}

.auth-section {
    width: 100%;
    max-width: 400px;
}

.auth-card {
    background: transparent;
    text-align: center;
}

.auth-card h2 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 2em;
    color: #ffffff;
    font-weight: normal;
}

.auth-form-group {
    margin-bottom: 25px;
    position: relative;
}

.auth-form-group input {
    width: 100%;
    padding: 15px 15px 15px 15px;
    border: none;
    border-bottom: 2px solid #ffffff;
    background: transparent;
    color: #ffffff;
    font-size: 1em;
    outline: none;
}

.auth-form-group input::placeholder {
    color: transparent;
}

.auth-form-group label {
    position: absolute;
    top: 15px;
    left: 0;
    font-size: 1em;
    color: #ffffff;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.auth-form-group input:focus + label,
.auth-form-group input:not(:placeholder-shown) + label {
    top: -10px;
    font-size: 0.8em;
    color: #a0a0a0;
}

.auth-button {
    width: 100%;
    padding: 15px;
    background-color: #ffffff;
    color: #2a5298;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.auth-button:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
}

.auth-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.auth-links {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9em;
    color: #ffffff;
}

.auth-links a {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease;
}

.auth-links a:hover {
    border-bottom: 1px solid #ffffff;
}

.auth-error {
    color: #ff6b6b;
    margin-bottom: 15px;
    font-size: 1em;
}

/* Responsive Adjustments */
@media (max-width: 500px) {
    .auth-links {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}